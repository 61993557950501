import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { basename } from './config/Config';


const Login = lazy(() => import('./views/pages/Login'));
const AgencyLogin = lazy(() => import('./views/pages/AgencyLogin'));
const AdminLogin = lazy(() => import('./views/pages/AdminLogin'));
const Home = lazy(() => import('./views/pages/Home'));
const ForgotPassword = lazy(() => import('./views/pages/ForgotPassword/index'));


const Loader = () => (
  <div className="suspenseLoader">
    <img alt="Loading..." src="/images/others/spinner.gif" />
  </div>
)

const Routes = () => (
  <BrowserRouter basename={basename}>
    <Suspense fallback={
      <Loader />
    }>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/agency" component={AgencyLogin} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/iab" component={AdminLogin} exact />
        <Route path="/" component={Home} />
        <Route path="**" render={() => (<Redirect to="/" />)} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)

export default Routes;